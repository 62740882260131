[id='kpi-edit-dialog'] {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 2rem 3rem;
  overflow: visible;

  @media (max-height: 800px) {
    overflow: scroll;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
  }

  > form {
    > span {
      color: red;
      text-align: center;
      display: block;
      margin: 1rem 0;
      max-width: 620px;
    }
    > header {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-bottom: 2rem;
      > button {
        background-color: #161617;
        backdrop-filter: blur(16px);
        color: white;
        font-weight: 600;
        padding: 0.5rem 1.25rem;
        border-radius: 33px;
      }

      > div {
        cursor: pointer;
        background-color: #7c81eb;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        aspect-ratio: 1 / 1;

        .close {
          width: 16px;
          aspect-ratio: 1 / 1;
          border-radius: 100%;
          padding: 0.5rem;
          background-color: white;
          mask-size: contain;
          mask-repeat: no-repeat;
          display: inline-block;
          mask-image: url('/icons/cross.svg');
        }
      }
    }

    > section {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 1.5rem;
      row-gap: 1.25rem;
      align-items: start;

      @media (min-width: 768px) {
        grid-template-columns: 1.25fr 0.75fr;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-shrink: 1;
        height: 100%;

        > label {
          font-weight: 600;
        }

        input[type='number'],
        input[type='text'] {
          background-color: #f4f4f4;
          padding: 1rem;
          border-radius: 7px;

          &.hidden {
            width: 100%;
            height: 1px;
            margin: -1px;
            margin-top: -1rem;
            padding: 0;
            border: 0;
            overflow: hidden;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            white-space: nowrap;
          }
        }

        input[type='checkbox'] {
          appearance: none;
          /* For iOS < 15 to remove gradient background */
          background-color: #fff;
          margin: 0;
          font: inherit;
          color: #b9b9b9;
          width: 2rem;
          height: 2rem;
          border: 0.15em solid #b9b9b9;
          border-radius: 0.15em;
          transform: translateY(-0.075em);
          display: grid;
          place-content: center;

          &::before {
            content: '';
            width: 1.25em;
            height: 1.25em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em #010305;
            background-color: CanvasText;
            transform-origin: bottom left;
            clip-path: polygon(
              14% 44%,
              0 65%,
              50% 100%,
              100% 16%,
              80% 0%,
              43% 62%
            );
          }

          &:checked::before {
            transform: scale(1);
          }

          &:focus {
            outline: max(2px, 0.15em) solid currentColor;
            outline-offset: max(2px, 0.15em);
          }
        }

        .disabled-select {
          height: 50px;
          background-color: #f4f4f4;
          border-radius: 7px;
        }

        .custom-select-wrapper {
          height: 50px;

          .custom-select {
            .custom-select-header > div {
              margin-left: 12px;
              margin-right: 12px;
              color: #3e3e3e;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .icon-arrow {
                width: 16px;
                aspect-ratio: 1 / 1;
                background-color: black;
                mask-size: contain;
                mask-repeat: no-repeat;
                display: inline-block;
                mask-image: url('/icons/arrow.svg');
                transform: rotate(90deg);
                margin-top: 8px;
                transition: transform 0.3s;
              }
            }

            &:focus-within {
              img {
                transform: rotate(90deg);
              }
              .custom-select-header {
                border-bottom: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                .icon-arrow {
                  margin-top: 0;
                  transform: rotate(-90deg);
                }
              }
              .custom-select-list {
                top: 100%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: none;
                z-index: 120;
                background-color: white;
                max-height: 120px;
                color: #3e3e3e;

                &::-webkit-scrollbar-track,
                ::-webkit-scrollbar-track {
                  background: transparent;
                }
                &::-webkit-scrollbar-thumb,
                ::-webkit-scrollbar-thumb {
                  background: #3e3e3e;
                  cursor: pointer;
                }

                > li {
                  margin-top: 1rem;
                  display: flex;
                  justify-content: flex-start;
                  margin-left: 12px;
                  margin-right: 12px;
                  &:first-child {
                    margin-top: 1rem;
                  }
                  &:last-child {
                    margin-bottom: 1rem;
                  }
                  .selected {
                    width: 100%;
                    color: #7c81eb;
                  }
                }
              }
            }
          }
        }

        .warehouse,
        .frequency {
          height: 100%;
          display: flex;
          gap: 1rem;
          position: relative;

          > div {
            display: flex;
            > input[type='radio'] {
              width: 1px;
              height: 1px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);

              + label {
                padding: 0 0.5rem;
                height: 100%;
                background-color: #f4f4f4;
                border-radius: 7px;
                color: #7c7c7c;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 14px;
                min-height: 40px;
              }
              &:checked + label {
                background-color: #010305;
                color: white;
                font-weight: 700;
              }
            }
          }

          &.warehouse {
            justify-content: space-between;
          }
        }

        &.checkbox-container {
          display: flex;

          > div,
          > div > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            label {
              font-weight: 400;
            }
          }

          > div {
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
