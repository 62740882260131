[id='kpi-edit-metadatas'] {
  position: relative;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      flex: 1;
      color: white;
      font-size: 2.75rem;
      font-weight: 600;
      text-wrap: balance;
      margin-top: 2.25rem;
      margin-bottom: 2rem;
      @media screen and (width > 768px) {
        font-size: 3.5rem;
      }
    }

    > div {
      display: flex;
      gap: 1rem;
      > button {
        background-color: #fcff61;
        border-radius: 33px;
        color: #42430e;
        font-weight: 600;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        img {
          transform: rotate(45deg);
          width: 12px;
          aspect-ratio: 1 / 1;
        }

        &.download {
          img {
            transform: none;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  > table {
    margin: 4rem 0 8rem 0;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 1rem;

    th,
    td {
      border: 1px solid #4b4b4b;
      text-align: start;
      color: #fff;
      padding: 1rem;
    }

    thead {
      background-color: #0c1a27;
      th {
        font-weight: 500;
      }
    }

    > tbody > tr > td:first-child {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 400px;
      overflow: hidden;
    }

    .units,
    .target,
    .buttons {
      text-align: center;
    }

    .buttons {
      > img {
        cursor: pointer;

        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }
}
