[id='kpi-export-dialog'] {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: auto;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 820px;
  margin: 0;
  padding: 2rem 3rem;
  overflow: auto;
  min-height: 30vh;

  > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
  }

  .dates {
    display: flex;
    gap: 2rem;
    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      input {
        border: 1px solid black;
        padding: 0.5rem;
      }
    }
  }

  .error {
    color: red;
  }

  .buttons {
    display: flex;
    gap: 3rem;

    button {
      padding: 0.5rem 1rem;
      border-radius: 33px;

      &:first-child {
        background-color: black;
        color: white;
      }

      &:last-child {
        background-color: #fcff61;
        font-weight: 600;
        border: 1px solid rgba(0, 0, 0, 0.7);
      }
    }
  }
}
